// color modifiers
$saturationHover: 10%;

// all used colors
$colorBrand: hsl(214, 61%, 39%);
$colorComplementary: #06a36f;
$colorBrandHover: saturate($colorBrand, $saturationHover);
$colorBrand2: #193141;
$colorBackground: #fcfcfc;
$colorGray: #7e7e7e;
$colorGrayDark: darken($colorGray, 10%);
$colorGrayDarker: darken($colorGray, 15%);
$colorGrayLight: lighten($colorGray, 45%);
$colorGrayDimmed: lighten($colorGray, 40%);
$colorGrayDimmedSofter: transparentize($colorGrayDimmed, 0.5);
$colorGraySoft: lighten($colorGray, 35%);
$colorGraySofter: lighten($colorGray, 25%);
$colorBlueLight: #0C9EED;
$colorWhite: #fff;
$colorBlack: #000;
$colorBlackNatural: #261C1C;
$colorPositive: hsl(160, 93%, 33%);
$colorWarning: #FAA84F;
$colorDanger: #E82809;
$colorPayment: #fcfbe7;

$colorOverlay: rgba(255, 246, 240, 0.75);

$colorGroupFree: $colorGray;
$colorGroupPartner: #a22828;
$colorGroupStartup: #737798;
$colorGroupProfi: #2f61ce;
$colorGroupCorporate: #1b2263;

$colorSocialFb: #1877f2;

$colorPanel: $colorWhite;
$colorPanelBorder: $colorGrayDimmed;

$colorText: $colorBlackNatural;
$colorFooter: $colorGrayDimmed;
$colorFooterText: $colorGray;

// form colors
$colorFormErrorBackground: lighten($colorDanger, 22%);
$colorTagPaid: lighten($colorPositive, 45%);
