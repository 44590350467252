@mixin word-break() {
	// white-space: pre-wrap;
	-ms-word-break: break-all;
	-ms-word-wrap: break-all;
	-webkit-word-break: break-word;
	-webkit-word-wrap: break-word;
	word-break: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

@mixin content-holder() {
	width: 100%;
	max-width: $sizeBreakpointLg;
	margin: 0 auto;
	padding: 0 1em;
}

@mixin button($colorText) {
	display: inline-block;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
	color: $colorText;
	position: relative;

	&:before {
		content: '';
		display: inline-block;
		height: 1rem;
		position: absolute;
		bottom: 0.3rem;
		opacity: 1;
		left: 1rem;
		right: 1rem;
		z-index: 0;
		border-radius: 30rem;
		filter: blur(0.8rem) brightness(0.95);
		transform-style: preserve-3d;
		transition: all $time-transition-ui-fast ease;
	}

	&__inner {
		display: flex;
		align-items: center;
		text-transform: inherit;
		$colorText: $colorText;
		cursor: pointer;
		border: none;
		padding: 0.6rem 1.3rem 0.5rem;
		border-radius: 30rem;
		position: relative;
		z-index: 2;
		will-change: transform, filter;
		transform-style: preserve-3d;
		transition: all $time-transition-ui-fast ease;
		word-break: normal;
	}

	&:hover {
		.button__inner {
			filter: brightness(1.05) contrast(1.05);
		}

		&:before {
			bottom: 0.2rem;
			opacity: 1;
		}
	}

	&:active {
		.button__inner {
			filter: brightness(1) contrast(1);
			transform: scale(0.94);
			transition: all $time-transition-ui-fast ease;
		}

		&:before {
			bottom: 0.4rem;
			filter: blur(0.3rem) brightness(0.8);
			transition: all $time-transition-ui-fast ease;
		}
	}
}

@mixin button-color($color) {
	& > button {
		color: $color;
	}
}

@mixin button-background($color) {
	.button__inner,
	&:before {
		background: $color;
		background: linear-gradient(135deg, $color 0%, $color 50%, lighten($color, 8%) 100%);
	}

	$saturatedColor: saturate($color, $saturationHover);

	&:hover .button__inner,
	&:hover:before {
		background: $saturatedColor;
		background: linear-gradient(135deg, $saturatedColor 0%, $saturatedColor 50%, lighten($saturatedColor, 8%) 100%);
	}
}

@mixin transition-ui-fast($transition-property: all) {
	transition: $transition-property $time-transition-ui-fast $time-transition-function-ui-fast $time-transition-transition-delay;
}

@mixin transition-ui-slow($transition-property: all) {
	transition: $transition-property $time-transition-ui-slow $time-transition-function-ui-slow $time-transition-transition-delay;
}

@mixin transition-ui-slow($transition-property: all) {
	transition: $transition-property $time-transition-ui-slow $time-transition-function-ui-slow $time-transition-transition-delay;
}

@keyframes keyframes-error {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@mixin animation-error() {
	animation: keyframes-error $time-transition-ui-slow $time-transition-function-ui-slow $time-transition-transition-delay;
}
