.students {
	color: $colorBrand2;

	&__block {
		height: 700px;

		&--top {
			background: linear-gradient(to right, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 60%), url(/media/images/students-notebook.jpg) no-repeat;
			background-size: 100%;
		}

		&--bottom {
			background: linear-gradient(to left, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 60%), url(/media/images/students-camera.jpg) no-repeat;
			background-size: 100%;
		}

	}

	&__heading {

		font-size: 5rem;
		text-transform: uppercase;
		line-height: 4.75rem;
		letter-spacing: -4px;

		&--top {
			width: 320px;
			margin: 8rem 0 2rem 2rem;
		}

		&--bottom {
			width: 360px;
			margin: 4rem 2rem 2rem auto;
			text-align: center;
		}
	}

	&__text {
		font-weight: bold;
		font-size: 12px;
		font-family: sans-serif;

		&--top {
			margin: 0 2.1rem;
			width: 400px;
		}

		&--bottom {
			text-align: center;
			width: 340px;
			margin: 1rem 2rem 1rem auto;
		}
	}

	&__button {
		display: inline-block;

		&--top {
			position: relative;
			margin-left: 2rem;
			bottom: -4rem;
		}

		&--bottom {
			position: absolute;
			right: 4rem;
			margin: 2rem 0 0 0;
		}
	}
}
