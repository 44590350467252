.u {
	&-holder {
		@include content-holder();
	}
	&-text-bold {
		font-weight: bold;
	}
	&-text-center {
		text-align: center;
	}
	&-uppercase {
		text-transform: uppercase;
	}
	&-subtext {
		color: $colorGray;
		font-size: 0.9rem;
	}
	&-basic-layout {
		padding-top: 2rem;
		padding-bottom: 3rem;
	}
	&-margin-bottom {
		margin-bottom: 2rem;
	}
	&-word-break {
		@include word-break();
	}
	&-color-success {
		color: $colorPositive !important;
	}
	&-color-danger {
		color: $colorDanger !important;
	}
	&-color-group-free {
		color: $colorGroupFree !important;
	}
	&-color-group-partner {
		color: $colorGroupPartner !important;
	}
	&-color-group-startup {
		color: $colorGroupStartup !important;
	}
	&-color-group-profi {
		color: $colorGroupProfi !important;
	}
	&-color-group-corporate {
		color: $colorGroupCorporate !important;
	}
	&-background-group-free {
		background: $colorGroupFree !important;
		background:
			linear-gradient(150deg, transparentize($colorWhite, 1), transparentize($colorWhite, 0.7) 90%),
			$colorGroupFree !important;
	}
	&-background-group-partner {
		background: $colorGroupPartner !important;
		background:
			linear-gradient(150deg, transparentize($colorWhite, 1), transparentize($colorWhite, 0.7) 90%),
			$colorGroupPartner !important;
	}
	&-background-group-startup {
		background: $colorGroupStartup !important;
		background:
			linear-gradient(150deg, transparentize($colorWhite, 1), transparentize($colorWhite, 0.7) 90%),
			$colorGroupStartup !important;
	}
	&-background-group-profi {
		background: $colorGroupProfi !important;
		background:
			linear-gradient(150deg, transparentize($colorWhite, 1), transparentize($colorWhite, 0.7) 90%),
			$colorGroupProfi !important;
	}
	&-background-group-corporate {
		background: $colorGroupCorporate !important;
		background:
			linear-gradient(150deg, transparentize($colorWhite, 1), transparentize($colorWhite, 0.7) 90%),
			$colorGroupCorporate !important;
	}
	&-border-bottom-dimmed {
		border-bottom: 1px solid $colorGrayDimmed;
	}
}
